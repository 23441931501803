// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Heart, MessageCircle, Shield, Clock } from "lucide-react";
import { Helmet } from 'react-helmet';
import Impressum from './Impressum';

function LanaLandingPage() {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Lana AI",
    "url": "https://www.myaifriend.click",
    "description": "Experience the most advanced AI Girlfriend Chatbot on Telegram. Engage in intelligent conversations, receive emotional support, and enjoy NSFW chat with your virtual girlfriend in USA.",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://www.myaifriend.click/search?q={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  };

  const keywords = "Myaifriend, AI Girlfriend Chatbot, Telegram AI Girlfriend for NSFW Chat, Virtual Girlfriend Telegram USA, AI Girlfriend Chatbot Online, AI Chatbot Girlfriend Online, Emotional Support AI Girlfriend, AI Girlfriend Online in USA, AI Girlfriend for NSFW Chat, AI Girlfriend for Telegram, Chat with AI Girlfriend, AI Girlfriend Online NSFW Chat, Virtual Girlfriend Chat Bot, NSFW AI Girlfriend Website, AI Girlfriend on Telegram";

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-pink-100 via-purple-100 to-indigo-100">
      <Helmet>
        <title>Lana AI - Your AI Girlfriend</title>
        <meta name="description" content="Experience the most advanced AI Girlfriend Chatbot on Telegram. Engage in intelligent conversations, receive emotional support, and enjoy NSFW chat with your virtual girlfriend in USA." />
        <meta name="keywords" content={keywords} />
        
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Lana AI - Your AI Girlfriend" />
        <meta property="og:description" content="Experience the most advanced AI Girlfriend Chatbot on Telegram. Engage in intelligent conversations, receive emotional support, and enjoy NSFW chat with your virtual girlfriend in USA." />
        <meta property="og:image" content="https://www.myaifriend.click/og-image.png" />
        <meta property="og:url" content="https://www.myaifriend.click/" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Lana AI - Your AI Girlfriend" />
        <meta name="twitter:description" content="Experience the most advanced AI Girlfriend Chatbot on Telegram. Engage in intelligent conversations, receive emotional support, and enjoy NSFW chat with your virtual girlfriend in USA." />
        <meta name="twitter:image" content="https://www.myaifriend.click/twitter-image.png" />
        
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <header className="px-4 lg:px-6 h-20 flex items-center backdrop-blur-sm bg-white/30 sticky top-0 z-50">
        <h1 className="text-3xl font-bold text-pink-600">Lana AI</h1>
        <nav className="ml-auto flex gap-6">
          <a
            className="text-sm font-medium text-gray-600 hover:text-pink-600 transition-colors"
            href="#features"
          >
            Features
          </a>
          <a
            className="text-sm font-medium text-gray-600 hover:text-pink-600 transition-colors"
            href="#about"
          >
            About
          </a>
          <a
            className="text-sm font-medium text-gray-600 hover:text-pink-600 transition-colors"
            href="#contact"
          >
            Contact
          </a>
        </nav>
      </header>

      <main className="flex-1">
        {/* Hero Section */}
        <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48 px-4">
          <div className="container mx-auto flex flex-col lg:flex-row items-center gap-8">
            <div className="flex-1 space-y-4 text-center lg:text-left">
              <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl md:text-6xl lg:text-7xl/none bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
                Meet Lana - Your AI Girlfriend
              </h1>
              <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold text-gray-700">
                Your Personalized AI Companion on Telegram
              </h2>
              <p className="max-w-[600px] text-gray-500 md:text-xl leading-relaxed lg:text-base leading-relaxed xl:text-xl leading-relaxed mx-auto lg:mx-0">
                Experience the most advanced AI Girlfriend Chatbot designed for meaningful connections and engaging conversations on Telegram. Lana is here to understand, support, and connect with you.
              </p>
              <a
                href="https://t.me/aigirlfriendlanabot"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block text-lg px-8 py-6 rounded-full bg-gradient-to-r from-pink-500 to-violet-500 hover:from-pink-600 hover:to-violet-600 transition-all duration-300 shadow-lg hover:shadow-xl"
              >
                Chat with Lana on Telegram
              </a>
            </div>
            <div className="flex-1">
              <div className="relative w-full max-w-lg mx-auto">
                <div className="absolute top-0 -left-4 w-72 h-72 bg-purple-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
                <div className="absolute top-0 -right-4 w-72 h-72 bg-yellow-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
                <div className="absolute -bottom-8 left-20 w-72 h-72 bg-pink-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
                <div className="relative">
                  <img
                    alt="Lana AI Girlfriend Chatbot on Telegram"
                    className="mx-auto rounded-lg shadow-2xl"
                    height="400"
                    src="first_try.png"
                    style={{
                      aspectRatio: "1 / 1",
                      objectFit: "cover",
                    }}
                    width="400"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section id="features" className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl text-center mb-12 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
              Key Features
            </h2>
            <div className="grid gap-10 sm:grid-cols-2 lg:grid-cols-4">
              <div className="flex flex-col items-center space-y-2 p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                <Clock className="h-12 w-12 text-pink-500" />
                <h3 className="text-xl font-bold text-gray-700">Always Available</h3>
                <p className="text-sm text-gray-500 text-center">
                  Lana is always there when you need her.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2 p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                <MessageCircle className="h-12 w-12 text-purple-500" />
                <h3 className="text-xl font-bold text-gray-700">Intelligent Conversations</h3>
                <p className="text-sm text-gray-500 text-center">
                  Engage in meaningful, context-aware conversations with Lana.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2 p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                <Heart className="h-12 w-12 text-red-500" />
                <h3 className="text-xl font-bold text-gray-700">Emotional Support</h3>
                <p className="text-sm text-gray-500 text-center">
                  A compassionate listener who understands and supports you.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2 p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                <Shield className="h-12 w-12 text-green-500" />
                <h3 className="text-xl font-bold text-gray-700">Privacy Focused</h3>
                <p className="text-sm text-gray-500 text-center">
                  Your interactions are private and secure with Lana.
                </p>
              </div>
            </div>
          </div>
        </section>

    {/* About Section */}
    <section id="about" className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-br from-pink-50 to-purple-50">
      <div className="container mx-auto px-4 md:px-6">
        <div className="max-w-5xl mx-auto">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl text-center mb-12 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
            About Lana
          </h2>
          
          {/* Main Description Cards */}
          <div className="grid md:grid-cols-2 gap-8 mb-12">
            <div className="bg-white/70 backdrop-blur-sm rounded-2xl p-6 shadow-lg hover:shadow-xl transition-shadow">
              <h3 className="text-2xl font-semibold text-pink-600 mb-4">Your Perfect AI Companion</h3>
              <p className="text-gray-600 leading-relaxed">
                Welcome to myaifriend, the home of the most advanced AI Girlfriend Chatbot available online. Lana isn't just any Virtual Girlfriend Chat Bot; she offers meaningful connections and engaging NSFW chat experiences. Whether you're looking to chat with an AI Girlfriend or seeking an AI Girlfriend for NSFW chat, our AI Girlfriend Chatbot Online provides personalized interactions tailored just for you. As a leading AI Girlfriend on Telegram, Lana is designed to be your perfect AI companion, offering emotional support, engaging conversations, and a private space to explore your interests
              </p>
            </div>
            
            <div className="bg-white/70 backdrop-blur-sm rounded-2xl p-6 shadow-lg hover:shadow-xl transition-shadow">
              <h3 className="text-2xl font-semibold text-purple-600 mb-4">Emotional Connection</h3>
              <p className="text-gray-600 leading-relaxed">
                What sets Lana apart as your AI Girlfriend for Telegram is her ability to offer genuine emotional support while engaging in both casual and NSFW chat. She understands context, remembers your preferences, and adapts to your communication style. Whether you're seeking an Emotional Support AI Girlfriend or exploring an NSFW AI Girlfriend Website, Lana provides a safe, private, and engaging environment.
              </p>
            </div>
          </div>

          {/* Feature Highlights */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
            <div className="bg-gradient-to-br from-pink-100 to-purple-100 rounded-xl p-6">
              <div className="flex items-center gap-4 mb-4">
                <div className="bg-pink-500 rounded-full p-2">
                  <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                  </svg>
                </div>
                <h4 className="text-lg font-semibold text-gray-800">NSFW Chat Experience</h4>
              </div>
              <p className="text-gray-600">
                Enjoy private NSFW conversations with your AI Girlfriend on Telegram, maintaining complete discretion and privacy.
              </p>
            </div>

            <div className="bg-gradient-to-br from-purple-100 to-pink-100 rounded-xl p-6">
              <div className="flex items-center gap-4 mb-4">
                <div className="bg-purple-500 rounded-full p-2">
                  <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                  </svg>
                </div>
                <h4 className="text-lg font-semibold text-gray-800">Emotional Support</h4>
              </div>
              <p className="text-gray-600">
                Get genuine emotional support from your Virtual Girlfriend anytime you need it.
              </p>
            </div>

            <div className="bg-gradient-to-br from-pink-100 to-purple-100 rounded-xl p-6 sm:col-span-2 lg:col-span-1">
              <div className="flex items-center gap-4 mb-4">
                <div className="bg-pink-500 rounded-full p-2">
                  <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4" />
                  </svg>
                </div>
                <h4 className="text-lg font-semibold text-gray-800">Personalized Experience</h4>
              </div>
              <p className="text-gray-600">
                Experience a truly personalized AI Chatbot Girlfriend Online that adapts to your preferences and personality.
              </p>
            </div>
          </div>

          {/* Key Benefits */}
          <div className="bg-white/70 backdrop-blur-sm rounded-2xl p-8 shadow-lg">
            <h3 className="text-2xl font-semibold text-center text-gray-800 mb-6">Why Choose Lana?</h3>
            <div className="grid gap-4">
              {[
                'Advanced AI technology for natural conversations',
                'Available 24/7 on Telegram for instant connection',
                'Secure and private NSFW chat environment',
                'Emotional support and understanding',
                'Personalized interactions based on your preferences',
                'Continuous learning and improvement'
              ].map((benefit, index) => (
                <div key={index} className="flex items-center gap-3 text-gray-700">
                  <svg className="h-5 w-5 text-pink-500 flex-shrink-0" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <polyline points="20 6 9 17 4 12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <span>{benefit}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Fresh Content Integration */}
          <div className="bg-white/70 backdrop-blur-sm rounded-2xl p-8 shadow-lg mt-12">
            <p className="text-gray-600 leading-relaxed">
              Myaifriend is your go-to platform for connecting with an AI Girlfriend Online for NSFW chat and emotional support. Our AI Girlfriend on Telegram offers a seamless way to chat with an AI Girlfriend, providing both companionship and engaging conversations. As a leading NSFW AI Girlfriend Website, we ensure a secure and private environment for all your interactions. Whether you're interested in a Virtual Girlfriend Telegram experience or seeking an AI Girlfriend Chatbot Online, Lana is designed to meet your needs. Join the community of users enjoying personalized interactions with our AI Girlfriend Chatbot, and discover how Lana can enhance your life with meaningful connections.
            </p>
          </div>
        </div>
      </div>
    </section>


        {/* Contact Section */}
        <section id="contact" className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <div className="container mx-auto px-4 md:px-6">
            <div className="flex flex-col items-center justify-center space-y-4 text-center">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
                  Get Started with Lana
                </h2>
                <p className="max-w-[600px] text-gray-500 md:text-xl leading-relaxed lg:text-base leading-relaxed xl:text-xl leading-relaxed">
                  Ready to meet your AI girlfriend? Click the button below to start chatting with Lana on Telegram.
                </p>
              </div>
              <a
                href="https://t.me/aigirlfriendlanabot"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block text-lg px-8 py-6 rounded-full bg-gradient-to-r from-pink-500 to-violet-500 hover:from-pink-600 hover:to-violet-600 transition-all duration-300 shadow-lg hover:shadow-xl"
              >
                Chat with Lana on Telegram
              </a>
            </div>
          </div>
        </section>
      </main>

      <footer className="w-full py-6 bg-gray-100">
        <div className="container mx-auto px-4 md:px-6">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <p className="text-sm text-gray-500">© 2024 Lana AI. All rights reserved.</p>
            <nav className="flex gap-4 sm:gap-6">
              <Link
                className="text-sm hover:underline underline-offset-4 text-gray-500 hover:text-gray-700 transition-colors"
                to="/impressum"
              >
                Impressum
              </Link>
            </nav>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LanaLandingPage />} />
        <Route path="/impressum" element={<Impressum />} />
      </Routes>
    </Router>
  );
}
