// Impressum.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function Impressum() {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Lana AI",
    "url": "https://www.myaifriend.click",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Otfried-Müller-Straße 37",
      "addressLocality": "Tübingen",
      "postalCode": "72076",
      "addressCountry": "DE"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "email": "info@myaifriend.click",
      "contactType": "customer service"
    },
    "sameAs": [
      "https://www.myaifriend.click"
      // Add more social profiles if available
    ]
    // Add more structured data as needed
  };

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <Helmet>
        <title>Impressum - Lana AI</title>
        <meta name="description" content="Impressum for Lana AI, providing legal information as per §5 TMG." />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <div className="container mx-auto max-w-4xl">
        <h1 className="text-4xl font-bold mb-8 text-gray-800">Impressum</h1>

        {/* Angaben gemäß § 5 TMG */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-700">Angaben gemäß § 5 TMG</h2>
          <p className="text-gray-600 mb-2">Vadim Borisov</p>
          <p className="text-gray-600">Otfried-Müller-Straße 37</p>
          <p className="text-gray-600">72076 Tübingen</p>
        </section>

        {/* Kontakt */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-700">Kontakt</h2>
          <p className="text-gray-600">
            E-Mail: <a href="mailto:info@myaifriend.click" className="text-blue-600 hover:underline">info@myaifriend.click</a>
          </p>
        </section>

        {/* Streitschlichtung */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-700">Streitschlichtung</h2>
          <p className="text-gray-600 mb-4">
            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>. Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </p>
          <p className="text-gray-600">
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
          </p>
        </section>

        {/* Haftung für Inhalte */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-700">Haftung für Inhalte</h2>
          <p className="text-gray-600 mb-4">
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. 
            Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen 
            zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          </p>
          <p className="text-gray-600">
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. 
            Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden 
            von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          </p>
        </section>

        {/* Back to Home */}
        <div className="mt-12 flex flex-col items-center">
          <Link to="/" className="text-blue-600 hover:underline mb-4">
            &larr; Zurück zur Startseite
          </Link>
          <p className="text-gray-500">Powered by <a href="https://www.myaifriend.click" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">www.myaifriend.click</a></p>
        </div>
      </div>
    </div>
  );
}
